import { useFormik } from 'formik';
import moment from 'moment';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import Select from 'react-select';
import { Button, Form, Input, Label } from 'reactstrap';
import defaultAvatar from 'src/assets/images/users/default-avatar.png';
import { InputField } from 'src/components';
import { config } from 'src/config';
import { setFromErrors } from 'src/helpers';
import store from 'src/rtk/app/store';
import {
  useLazyGetFcpsPartOneInfoQuery,
} from 'src/rtk/features/educational/educationalApi';
import { useGetLookupDataQuery } from 'src/rtk/features/setup/lookup/lookupDataApi';
import { useGetSubjectInfosQuery } from 'src/rtk/features/setup/subjectInfo/subjectInfoGlobalApi';
import {
  useCreatePersonalProfileMutation,
  useGetPersonalProfileQuery,
} from 'src/rtk/features/trainee/traineeApi';
import * as Yup from 'yup';
import loader from '../../assets/images/loader.gif';
import { useGetDepartmentsByInstituteQuery } from 'src/rtk/features/setup/department/departmentApi';

interface PersonalProfileProps {
  goToNext?: any;
}

const initialValues: StorePersonalAndAddress = {
  subject_id: '',
  full_name: '',
  fathers_name: '',
  mothers_name: '',
  bangla_name: '',
  religion: '',
  spouse_name: '',
  date_of_birth: '',
  national_id: '',
  mobile: 0,
  email: '',
  telephone: '',
  picture: '',
  signature: '',

  present_address: '',
  present_division: '',
  present_district: '',
  present_police_station: '',
  permanent_address: '',
  permanent_division: '',
  permanent_district: '',
  permanent_police_station: '',

  mailing_address: '',
};
const FILE_SIZE = 20 * 1024 * 1024;

const PersonalProfile = (props: PersonalProfileProps) => {
  const [subjectLists, setSubjectList] = useState<any>([]);
  const [photo, setPhoto] = useState('');
  const [signature, setSignature] = useState('');
  const [sameAsPresentAddress, setSameAsPresentAddress] =
    useState<boolean>(false);
  const [spouseCheck, setSpouseCheck] = useState(false);

  const { data: divisionLookupData, isLoading: isDivisionLoading } = useGetLookupDataQuery('division');
  const presentDivision: Lookup[] =
    (divisionLookupData as any)?.data[0]?.childs || [];
  const permanentDivision: Lookup[] =
    (divisionLookupData as any)?.data[0]?.childs || [];

  const [presentDistrict, setPresentDistrict] = useState<Lookup[]>([]);

  const [presentPoliceStation, setPresentPoliceStation] = useState<Lookup[]>(
    [],
  );

  const [permanentDistrict, setPermanentDistrict] = useState<Lookup[]>([]);

  const [permanentPoliceStation, setPermanentPoliceStation] = useState<
    Lookup[]
  >([]);

  const [getFcpsPartOneInfo, { data: fcpsPart,
    isSuccess: isFcpsPartSuccess,
    isError: isFcpsPartError,
    isLoading: isFcpsPartLoading,
    error: fcpsPartError
  }] = useLazyGetFcpsPartOneInfoQuery();

  const { data: personalProfile, isError: isDeptFetchError, isLoading: isProfileLoading } =
    useGetPersonalProfileQuery();

  const { data: subjectList, isError: isSubFetchError, isLoading: isSubFetchLoading } =
    useGetSubjectInfosQuery();

  const allSubjects: SelectOption[] =
    subjectList?.data?.map((subject) => ({
      value: subject.id!,
      label: subject.subject_name,
    })) || [];

  const [createPersonalProfile, { isSuccess, isError, error, isLoading }] =
    useCreatePersonalProfileMutation();
  
  const  { data: departmentLookupData } = useGetDepartmentsByInstituteQuery('');

  const err: CustomError = error as CustomError;

  const localStateHandler = async (
    setValueFrom: Lookup[],
    setValueTo: any,
    selectedValue: string,
  ) => {
    const selectedParent = await setValueFrom?.find(
      (item) => item.name === selectedValue,
    );
    setValueTo(selectedParent?.childs as any);
  };

  const handlePermanentDivision = (e: any) => {
    const { value } = e.target;
    localStateHandler(permanentDivision, setPermanentDistrict, value);
    setPermanentPoliceStation([]);
    setFieldValue('permanent_division', value);
  };

  const handlePermanentDistrict = (e: any) => {
    const { value } = e.target;
    localStateHandler(permanentDistrict, setPermanentPoliceStation, value);
    setFieldValue('permanent_district', value);
  };

  const handlePresentPoliceStation = (e: any) => {
    const { value } = e.target;
    setFieldValue('present_police_station', value);
  };

  const handlePresentDivision = (e: any) => {
    const { value } = e.target;
    localStateHandler(presentDivision, setPresentDistrict, value);
    setPresentPoliceStation([]);
    setFieldValue('present_division', value);
  };

  const handlePresentDistrict = (e: any) => {
    const { value } = e.target;
    localStateHandler(presentDistrict, setPresentPoliceStation, value);
    setFieldValue('present_district', value);
  };

  const handlePermanentPoliceStation = (e: any) => {
    const { value } = e.target;
    setFieldValue('permanent_police_station', value);
  };

  const calculateAge = (birthday: any) => {
    var ageDifMs = Date.now() - birthday;
    var ageDate = new Date(ageDifMs); // miliseconds from epoch
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  };

  const checkSameAsPresentAddress = (presentAddress: any, permanentAddress: any) => {
    if (presentAddress && permanentAddress) {
      if (presentAddress?.address_line_1 === permanentAddress?.address_line_2 &&
        presentAddress?.division === permanentAddress?.division &&
        presentAddress?.district === permanentAddress?.district &&
        presentAddress?.police_station === permanentAddress?.police_station) {
        setSameAsPresentAddress(true);
      } else {
        setSameAsPresentAddress(false);
      }
    }
  };

  const {
    handleSubmit,
    getFieldProps,
    touched,
    errors,
    values,
    setValues,
    handleChange,
    resetForm,
    setErrors,
    setFieldValue,
    setFieldTouched,
  } = useFormik({
    initialValues,
    validationSchema: Yup.object({
      full_name: Yup.string().required('Name is Required!'),
      subject_id: Yup.string().required('Please Select a Subject'),
      fathers_name: spouseCheck ? Yup.string() : Yup.string().matches(/^[A-Za-z.\s]*$/, "Only contain letters and spaces").required('Fathers Name is Required!'),
      mothers_name: Yup.string().matches(/^[A-Za-z.\s]*$/, "Only contain letters and spaces").required('Mothers Name is Required!'),
      bangla_name: Yup.string()
        .matches(/^[\u0980-\u09FF !@#$%^&*(),.?":{}|<>]+$/, 'Invalid Bangla name')
        .min(2, 'Name is too short')
        .max(100, 'Name is too long')
        .required('Name is required'),
      religion: Yup.string().required('Religion Name is Required!'),
      spouse_name: spouseCheck ? Yup.string().matches(/^[A-Za-z.\s]*$/, "Only contain letters and spaces").required('Spouse Name is Required!') : Yup.string(),
      date_of_birth: Yup.string()
        .required('Date of Birth is Required!')
        .test('birthday', 'Age should be minimum 18 years', function (val) {
          return calculateAge(new Date(val!)) >= 18;
        }),
      national_id: Yup.string().required('NID is Required!'),
      mobile: Yup.string().required('Mobile is Required!'),
      telephone: Yup.string()
        .max(14, 'Invalid mobile number')
        .matches(/^(?:\+88|88)?(01[0-9]\d{8})$/, 'Invalid phone number').nullable(),
      picture: photo
        ? Yup.mixed()
          .test('is-image', 'Must be an Image', (value) => {
            if (value) {
              return ['image/jpeg', 'image/png', 'image/jpg'].includes(
                value.type,
              );
            }
            return true;
          })
          .test('file-size', 'Image too Large', (value) => {
            if (value) {
              return value.size <= FILE_SIZE;
            }
            return true;
          })
        : Yup.mixed()
          .required('Image is Required!')
          .test('is-image', 'Must be an Image', (value) => {
            if (value) {
              return ['image/jpeg', 'image/png', 'image/jpg'].includes(
                value.type,
              );
            }
            return true;
          })
          .test('file-size', 'Image too Large', (value) => {
            if (value) {
              return value.size <= FILE_SIZE;
            }
            return true;
          }),

      signature: signature
        ? Yup.mixed()
          .test('is-image', 'Must be an Image', (value) => {
            if (value) {
              return ['image/jpeg', 'image/png', 'image/jpg'].includes(
                value.type,
              );
            }
            return true;
          })
          .test('file-size', 'Image too large', (value) => {
            if (value) {
              return value.size <= FILE_SIZE;
            }
            return true;
          })
        : Yup.mixed()
          .required('Image is Required!')
          .test('is-pdf', 'Must be an Image', (value) => {
            if (value) {
              return ['image/jpeg', 'image/png', 'image/jpg'].includes(
                value.type,
              );
            }
            return true;
          })
          .test('file-size', 'Image too Large', (value) => {
            if (value) {
              return value.size <= FILE_SIZE;
            }
            return true;
          }),
      permanent_address: Yup.string().required('Permanent Address is Required!'),
      permanent_division: Yup.string().required(
        'Permanent Division is Required!',
      ),
      permanent_district: Yup.string().required(
        'Permanent District is Required!',
      ),
      permanent_police_station: Yup.string().required(
        'Permanent Police Station is Required!',
      ),
      present_address: Yup.string().required('Present Address is Required!'),
      present_division: Yup.string().required('Present Division is Required!'),
      present_district: Yup.string().required('Present District is Required!'),
      present_police_station: Yup.string().required(
        'Present Police Station is Required!',
      ),
    }),
    onSubmit: (values: any) => {
      values.date_of_birth ? values.date_of_birth = moment(
        values.date_of_birth,
      ).format('YYYY-MM-DD') : values.date_of_birth = '';

      const formData = new FormData();
      Object.keys(values).forEach((key) => {
        formData.append(key, values[key]);
      });
      createPersonalProfile(formData);
    },
  });

  useEffect(() => {
    getFcpsPartOneInfo(store.getState().auth.user.details.bmdc_registration_no!);
  }, []);

  useEffect(() => {
    if (personalProfile && !isDeptFetchError) {
      const personalInfo = personalProfile?.data;
      personalInfo?.details?.personal_info?.picture &&
        setPhoto(
          `${config?.APP_URL}${personalInfo?.details?.personal_info?.picture}`,
        );
      personalInfo?.details?.personal_info?.signature &&
        setSignature(
          `${config?.APP_URL}${personalInfo?.details?.personal_info?.signature}`,
        );

      checkSameAsPresentAddress(personalInfo?.details?.latest_present_address, personalInfo?.details?.latest_permanent_address);

      // localStateHandler(permanentDivision, setPermanentDistrict, personalInfo?.details?.latest_permanent_address?.division!);
      // setPermanentPoliceStation([]);
      // setFieldValue('permanent_division', personalInfo?.details?.latest_permanent_address?.division!);

      // localStateHandler(permanentDistrict, setPermanentPoliceStation, personalInfo?.details?.latest_permanent_address?.district!);
      // setFieldValue('permanent_district', personalInfo?.details?.latest_permanent_address?.district!);

      // setFieldValue('permanent_police_station', personalInfo?.details?.latest_permanent_address?.police_station);

      // localStateHandler(presentDivision, setPresentDistrict, personalInfo?.details?.latest_present_address?.division!);
      // setPresentPoliceStation([]);
      // setFieldValue('present_division', personalInfo?.details?.latest_present_address?.division!);

      // localStateHandler(presentDistrict, setPresentPoliceStation, personalInfo?.details?.latest_present_address?.district!);
      // setFieldValue('present_district', personalInfo?.details?.latest_present_address?.district!);

      // setFieldValue('present_police_station', personalInfo?.details?.latest_present_address?.police_station);

      personalInfo?.details?.personal_info?.spouse_name && setSpouseCheck(true);

      setValues({
        subject_id: personalInfo?.details?.subject_id || '',
        full_name: personalInfo?.details?.personal_info?.full_name || '',
        fathers_name: personalInfo?.details?.personal_info?.fathers_name || '',
        mothers_name: personalInfo?.details?.personal_info?.mothers_name || '',
        bangla_name: personalInfo?.details?.personal_info?.bangla_name || '',
        religion: personalInfo?.details?.personal_info?.religion || '',
        spouse_name: personalInfo?.details?.personal_info?.spouse_name || '',
        date_of_birth:
          personalInfo?.details?.personal_info?.date_of_birth || '',
        national_id: personalInfo?.details?.personal_info?.national_id || '',
        mobile: personalInfo?.details?.personal_info?.mobile || 0,
        email: personalInfo?.details?.personal_info?.email || '',
        telephone: personalInfo?.details?.personal_info?.telephone || '',
        picture: '',
        signature: '',
        present_division:
          personalInfo?.details?.latest_present_address?.division || '',
        present_district:
          personalInfo?.details?.latest_present_address?.district || '',
        present_police_station:
          personalInfo?.details?.latest_present_address?.police_station || '',
        present_address:
          personalInfo?.details?.latest_present_address?.address_line_1 || '',
        permanent_division:
          personalInfo?.details?.latest_permanent_address?.division || '',
        permanent_district:
          personalInfo?.details?.latest_permanent_address?.district || '',
        permanent_police_station:
          personalInfo?.details?.latest_permanent_address?.police_station || '',
        permanent_address:
          personalInfo?.details?.latest_permanent_address?.address_line_2 || '',
        mailing_address: personalInfo?.details?.mailing_address || 'present_address',
      });
    }
  }, [personalProfile, isDeptFetchError]);

  useEffect(() => {
    if (isFcpsPartSuccess && !isFcpsPartError && !isDeptFetchError && !isSubFetchError && !isSubFetchLoading && !isProfileLoading) {
      if (!personalProfile?.data?.details?.subject_id) {
        const selectedSubjectId: any = subjectList?.data?.find((subject: SubjectInfo, index: number) => subject.subject_name === fcpsPart?.data?.subject)?.id!;
        setFieldValue('subject_id', selectedSubjectId ?? '');
        setFieldValue('fathers_name', fcpsPart?.data?.fathers_name ?? '');
        setFieldValue('mothers_name', fcpsPart?.data?.mothers_name ?? '');
      }
    }
  }, [isFcpsPartSuccess, isFcpsPartError, isDeptFetchError, isProfileLoading, isSubFetchError, isSubFetchLoading]);

  useEffect(() => {
    if (personalProfile && permanentDivision?.length > 0) {
      const personalInfo = personalProfile?.data;
      localStateHandler(
        permanentDivision,
        setPermanentDistrict,
        personalInfo?.details?.latest_permanent_address?.division as string,
      );
    }
  }, [personalProfile, permanentDivision]);

  useEffect(() => {
    const personalInfo = personalProfile?.data;
    localStateHandler(
      permanentDistrict,
      setPermanentPoliceStation,
      personalInfo?.details?.latest_permanent_address?.district as string,
    );
  }, [personalProfile, permanentDistrict]);

  useEffect(() => {
    if (personalProfile && presentDivision?.length > 0) {
      const personalInfo = personalProfile?.data;
      localStateHandler(
        presentDivision,
        setPresentDistrict,
        personalInfo?.details?.latest_present_address?.division as string,
      );
    }
  }, [personalProfile, presentDivision]);

  useEffect(() => {
    const personalInfo = personalProfile?.data;
    localStateHandler(
      presentDistrict,
      setPresentPoliceStation,
      personalInfo?.details?.latest_present_address?.district as string,
    );
  }, [personalProfile, presentDistrict]);

  useEffect(() => {
    if (!isLoading && isSuccess) {
      // resetForm();
      toast.dismiss();
      toast.success('Personal And Address Information Stored Successfully');
      // setLoading(false);
      props.goToNext();
    }
    if (isError) {
      toast.dismiss();
      toast.error(err?.message || 'Something Went Wrong!');
      setFromErrors(err.data, setErrors);
    }
    // if(isLoading){
    //   toast.loading('Loading...', {
    //     position: 'top-right',
    //   });
    // }
  }, [isSuccess, isError, isLoading]);

  // useEffect(() => {
  //   console.log(saveChanges);
  //   if (saveChanges) {
  //     handleSubmit();
  //   }
  // }, [saveChanges]);

  useEffect(() => {
    if (!isSubFetchError && subjectList) setSubjectList(subjectList?.data);
  }, [isSubFetchError, subjectList]);

  useEffect(() => {
    if (!personalProfile?.data?.details?.mailing_address) {
      setFieldValue('mailing_address', 'present_address');
    }
  }, []);

  const selectOptionHandler = (optionList: Lookup[], selectedOpt: any) => {
    return (
      <>
        {selectedOpt
          ? optionList?.map((option) => (
            <option
              key={option.id}
              selected={option.name === selectedOpt}
              value={option.name}
            >
              {option.name}
            </option>
          ))
          : optionList?.map((option) => (
            <option key={option.id} value={option.name}>
              {option.name}
            </option>
          ))}
      </>
    );
  };

  const handlePresentAddress = async (e: any) => {
    if (e.target.checked) {
      setSameAsPresentAddress(true);
      setFieldValue('permanent_address', values.present_address);
      setFieldValue('permanent_division', values.present_division);
      await localStateHandler(
        presentDivision,
        setPermanentDistrict,
        values.present_division!,
      );
      setPermanentPoliceStation([]);
      setFieldValue('permanent_district', values.present_district);
      await localStateHandler(
        presentDistrict,
        setPermanentPoliceStation,
        values.present_district!,
      );
      setFieldValue('permanent_police_station', values.present_police_station);
    } else {
      setSameAsPresentAddress(false);
      setFieldValue('permanent_address', '');
      setFieldValue('permanent_division', '');
      setFieldValue('permanent_district', '');
      setFieldValue('permanent_police_station', '');
    }
  };

  const handlePictureSelect = (event: any) => {
    const selectedPicture = event.target.files[0];
    setPhoto(URL.createObjectURL(selectedPicture));
  };

  const handleSignatureSelect = (event: any) => {
    const selectedSignature = event.target.files[0];
    setSignature(URL.createObjectURL(selectedSignature));
  };

  const handleCheck = (event: any) => {
    if (event.target.checked) {
      setSpouseCheck(true);
    } else {
      setSpouseCheck(false);
    }
  };

  return (
    <div className="mb-4">
      {isLoading || isProfileLoading || isDivisionLoading ? (
        <div className="overlay">
          {/* <Circles color='#0d6efd' height={100} width={100}/> */}
          <img src={loader} alt="Loading" height={100} width={300} />
        </div>
      ) : null}
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
        encType="multipart/form-data"
      >
        <h5 className="mb-3 block-heading">Personal Info</h5>
        <div className="row">
          <div className="col-md-6">
            {personalProfile?.data?.type === 'Trainee' || personalProfile?.data?.type === 'Supervisor' || personalProfile?.data?.type === 'Fellow' ? (
              <div className="mb-3">
                <Label className="required-field">Subject</Label>
                {/* <InputField
                  name="subject_id"
                  type="select"
                  onChange={handleChange}
                  value={values.subject_id}
                  options={subjectLists.map((sub: any) => {
                    return { value: sub.id, label: sub.subject_name };
                  })}
                  invalid={{ errors, touched }}
                /> */}
                <Select
                  isClearable={true}
                  isSearchable={true}
                  value={allSubjects.find(
                    (option: any) =>
                      option.value === values.subject_id,
                  )}
                  onChange={(e: any) => {
                    if (e?.value) {
                      let selectedId = e.value;
                      setFieldValue('subject_id', selectedId);
                    } else {
                      setFieldValue('subject_id', '');
                    }
                  }}
                  options={allSubjects}
                  placeholder='Select Subject'
                  name="subject_id"
                />
                {errors.subject_id && touched.subject_id ? (
                  <div className="text-danger error-size">
                    {errors.subject_id as string}
                  </div>
                ) : null}
              </div>
            ) : null}
            <div className="mb-3">
              <Label for="full_name" className="required-field">
                Full Name
              </Label>
              <InputField
                type="text"
                placeholder="Enter Your Name"
                readOnly
                invalid={{ errors, touched }}
                {...getFieldProps('full_name')}
              />
            </div>
            <div className="mb-3">
              <Label for="bangla_name" className="required-field">Full Name(বাংলায়)</Label>
              <InputField
                type="text"
                placeholder="Enter Name in Bangla"
                invalid={{ errors, touched }}
                {...getFieldProps('bangla_name')}
              />
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="checkbox"
                id="spouse_name_check"
                name="spouse_name_check"
                onChange={(e) => handleCheck(e)}
                checked={spouseCheck}
              />
              <label className="form-check-label" htmlFor="spouse_name_check">
                Spouse
              </label>
            </div>
            {!spouseCheck ? (
              <div className="mb-3">
                <Label for="fathers_name" className="required-field">Father's Name</Label>
                <InputField
                  type="text"
                  placeholder="Enter Your Fathers Name"
                  invalid={{ errors, touched }}
                  {...getFieldProps('fathers_name')}
                />
              </div>
            ) : (
              <div className="mb-3">
                <Label for="spouse_name" className="required-field">Spouse Name</Label>
                <InputField
                  type="text"
                  placeholder="Enter Your Spouse Name"
                  invalid={{ errors, touched }}
                  {...getFieldProps('spouse_name')}
                />
              </div>
            )}

            <div className="mb-3">
              <Label for="mothers_name" className="required-field">
                Mother's Name
              </Label>
              <InputField
                type="text"
                placeholder="Enter Your Mothers Name"
                invalid={{ errors, touched }}
                {...getFieldProps('mothers_name')}
              />
            </div>

          </div>
          <div className="col-md-6">
            <div className="mb-3">
              <div className="image-upload-wrapper">
                <div className="image-preview">
                  <img src={photo ? photo : defaultAvatar} alt="" />
                </div>
                <div className="image-upload">
                  <Label for="picture" className="required-field">
                    Photograph
                  </Label>{' '}
                  <span className="text-muted fw-light">
                    (Please upload your Passport size photo)
                  </span>
                  {/* <i
                    className="fas fa-info-circle"
                    title="Format must be JPG,PNG,JPEG. Size cannot be greater 200KB"
                  ></i> */}
                  <InputField
                    type="file"
                    accept=".jpg, .jpeg, .png"
                    name="picture"
                    placeholder="Upload Picture"
                    onChange={(e) => {
                      setFieldValue('picture', e.target.files[0]);
                      handlePictureSelect(e);
                    }}
                    invalid={{ errors, touched }}
                  />
                </div>
              </div>
            </div>
            <div className="mb-3">
              <div className="image-upload-wrapper">
                <div className="image-preview">
                  <img src={signature ? signature : defaultAvatar} alt="" />
                </div>
                <div className="image-upload">
                  <Label for="signature" className="required-field">
                    Signature
                  </Label>{' '}
                  <span className="text-muted fw-light">
                    (Please upload your scaned signature)
                  </span>
                  {/* <i
                    className="fas fa-info-circle"
                    title="Format must be JPG,PNG,JPEG. Size cannot be greater 200KB"
                  ></i> */}
                  <InputField
                    type="file"
                    accept=".jpg, .jpeg, .png"
                    name="signature"
                    placeholder="Upload Signature"
                    onChange={(e) => {
                      setFieldValue('signature', e.target.files[0]);
                      handleSignatureSelect(e);
                    }}
                    invalid={{ errors, touched }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 mb-3">
            <Label for="email" className="required-field">
              Email
            </Label>
            <InputField
              type="text"
              placeholder="Enter Your Email"
              readOnly
              invalid={{ errors, touched }}
              {...getFieldProps('email')}
            />
          </div>
          <div className="col-md-6 mb-3">
            <Label for="date_of_birth" className="required-field">
              Date of Birth
            </Label>{' '}
            <span className="text-muted fw-light">
              (Age cannot be less than 18 years.)
            </span>
            {/* <i
              className="fas fa-info-circle"
              title="Age cannot be less than 18 years."
            ></i> */}
            <InputField
              type="date"
              placeholder="Select Date Of Birth"
              invalid={{ errors, touched }}
              {...getFieldProps('date_of_birth')}
            />
          </div>

          <div className="col-md-6 mb-3">
            <Label for="mobile" className="required-field">
              Mobile
            </Label>
            <InputField
              type="text"
              placeholder="Enter Your Mobile Number"
              readOnly
              invalid={{ errors, touched }}
              {...getFieldProps('mobile')}
            />
          </div>
          <div className="col-md-6 mb-3">
            <Label for="telephone">Alternative Mobile Number</Label>{' '}
            <span className="text-muted fw-light">
              (Enter your Alternative Mobile Number (if any)).
            </span>
            {/* <i
              className="fas fa-info-circle"
              title="Enter your Alternative Mobile Number (if any)."
            ></i> */}
            <InputField
              type="text"
              placeholder="Enter Your Alternative Number"
              invalid={{ errors, touched }}
              {...getFieldProps('telephone')}
            />
          </div>

          <div className="col-md-6 mb-3">
            <Label for="national_id" className="required-field">
              NID
            </Label>
            <InputField
              type="text"
              placeholder="Enter Your National Id"
              readOnly
              invalid={{ errors, touched }}
              {...getFieldProps('national_id')}
            />
          </div>

          <div className="col-md-6 mb-3">
            <Label for="religion" className="required-field">Religion</Label>
            <InputField
              type="select"
              placeholder="Enter Your Religion"
              options={[
                { value: 'Islam', label: 'Islam' },
                { value: 'Hinduism', label: 'Hinduism' },
                { value: 'Buddhism', label: 'Buddhism' },
                { value: 'Christianity', label: 'Christianity' },
                { value: 'Judaism ', label: 'Judaism ' },
                { value: 'Others ', label: 'Others ' },
              ]}
              invalid={{ errors, touched }}
              {...getFieldProps('religion')}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <h5 className="block-heading">Present Address</h5>
          </div>
        </div>
        <div className='row'>
          <div className="col-lg-3">
            <div className="form-check form-check-inline mb-3">
              <Input
                className="form-check-input"
                type="checkbox"
                name="mailing_address"
                id="present-mail"
                onChange={(e) => {
                  handleChange(e);
                  if (e.target.checked) {
                    setFieldValue('mailing_address', 'present_address');
                  }
                }}
                checked={values.mailing_address === 'present_address'}
              />
              <label className="form-check-label" htmlFor="present-mail">
                Mailing Address?
              </label>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-3">
            <div className="mb-3">
              <Label for="present_division" className="required-field">
                Division
              </Label>
              <select
                className="form-select"
                onChange={handlePresentDivision}
                name="present_division"
                value={values.present_division}
              >
                <option value="">Select Division</option>
                {selectOptionHandler(
                  presentDivision,
                  personalProfile?.data?.details?.latest_present_address
                    ?.division,
                )}
              </select>
              {errors.present_division && touched.present_division ? (
                <div className="text-danger error-size">{errors.present_division as string}</div>
              ) : null}
            </div>
          </div>
          <div className="col-lg-3">
            <div className="mb-3">
              <Label for="present_district" className="required-field">
                District
              </Label>
              <select
                className="form-select"
                onChange={handlePresentDistrict}
                name="present_district"
                value={values.present_district}
              >
                <option value="">Select District</option>
                {selectOptionHandler(
                  presentDistrict,
                  personalProfile?.data?.details?.latest_present_address
                    ?.district,
                )}
              </select>
              {errors.present_district && touched.present_district ? (
                <div className="text-danger error-size">{errors.present_district as string}</div>
              ) : null}
            </div>
          </div>
          <div className="col-lg-3">
            <div className="mb-3">
              <Label for="present_police_station" className="required-field">
                Upazila/City Corporation/Thana
              </Label>
              <select
                className="form-select"
                onChange={handlePresentPoliceStation}
                name="present_police_station"
                value={values.present_police_station}
              >
                <option value="">Select Upazila/City Corporation/Thana</option>
                {selectOptionHandler(
                  presentPoliceStation,
                  personalProfile?.data?.details?.latest_present_address
                    ?.police_station,
                )}
              </select>
              {errors.present_police_station &&
                touched.present_police_station ? (
                <div className="text-danger error-size">
                  {errors.present_police_station as string}
                </div>
              ) : null}
            </div>
          </div>
          <div className="col-lg-3">
            <div className="mb-3">
              <Label for="present_address" className="required-field">
                Address
              </Label>
              <InputField
                type="textarea"
                placeholder="Enter Your Present Address"
                invalid={{ errors, touched }}
                {...getFieldProps('present_address')}
              />
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col'>
            <h5 className="block-heading">Permanent Address</h5>
          </div>
        </div>

        <div className='row'>
          <div className="col-lg-3">
            <div className="form-check form-check-inline mb-3">
              <Input
                className="form-check-input"
                type="checkbox"
                name="mailing_address"
                id="permanent_mail"
                onChange={(e) => {
                  handleChange(e);
                  if (e.target.checked) {
                    setFieldValue('mailing_address', 'permanent_address');
                  }
                }}
                checked={values.mailing_address === 'permanent_address'}
              />
              <label className="form-check-label" htmlFor="permanent_mail">
                Mailing Address?
              </label>
            </div>
          </div>
          <div className='col-lg-3'>
            <div className="d-inline">
              <input
                className="d-inline-block me-2"
                name="sameAsPresentAddress"
                id="same-As-Present-Address"
                type="checkbox"
                checked={sameAsPresentAddress}
                onChange={(e) => handlePresentAddress(e)}
              />
              <Label for="same-As-Present-Address" className="form-check-label">
                Same as Present Address
              </Label>
            </div>
          </div>
        </div>

        <div className='row'>
          <div className="col-lg-3">
            <div className="mb-3">
              <Label for="permanent_division" className="required-field">
                Division
              </Label>
              <select
                className="form-select"
                onChange={handlePermanentDivision}
                name="permanent_division"
                value={values.permanent_division}
              >
                <option> Select Division</option>
                {selectOptionHandler(
                  permanentDivision,
                  personalProfile?.data?.details?.latest_permanent_address
                    ?.division,
                )}
              </select>
              {errors.permanent_division && touched.permanent_division ? (
                <div className="text-danger error-size">{errors.permanent_division as string}</div>
              ) : null}
            </div>
          </div>
          <div className="col-lg-3">
            <div className="mb-3">
              <Label for="permanent_district" className="required-field">
                District
              </Label>
              <select
                className="form-select"
                onChange={handlePermanentDistrict}
                name="permanent_district"
                value={values.permanent_district}
              >
                <option value="">Select District</option>
                {selectOptionHandler(
                  permanentDistrict,
                  personalProfile?.data?.details?.latest_permanent_address
                    ?.district,
                )}
              </select>
              {errors.permanent_district && touched.permanent_district ? (
                <div className="text-danger error-size">{errors.permanent_district as string}</div>
              ) : null}
            </div>
          </div>
          <div className="col-lg-3">
            <div className="mb-3">
              <Label for="permanent_police_station" className="required-field">
                Upazila/City Corporation/Thana
              </Label>
              <select
                className="form-select"
                onChange={handlePermanentPoliceStation}
                name="permanent_police_station"
                value={values.permanent_police_station}
              >
                <option value="">Select Upazila/City Corporation/Thana</option>
                {selectOptionHandler(
                  permanentPoliceStation,
                  personalProfile?.data?.details?.latest_permanent_address
                    ?.police_station,
                )}
              </select>
              {errors.permanent_police_station &&
                touched.permanent_police_station ? (
                <div className="text-danger error-size">
                  {errors.permanent_police_station as string}
                </div>
              ) : null}
            </div>
          </div>
          <div className="col-lg-3">
            <div className="mb-3">
              <Label for="permanent_address" className="required-field">
                {' '}
                Address
              </Label>
              <InputField
                type="textarea"
                placeholder="Enter Your Permanent Address"
                invalid={{ errors, touched }}
                {...getFieldProps('permanent_address')}
              />
            </div>
          </div>
        </div>

        <div className="d-flex justify-content-end mt-1">
          {personalProfile?.data?.details?.profile_approval_status === 'Approve' ?
            <div>
              <Button
                className='me-1'
                color="success"
                type="submit"
                onClick={() => handleSubmit}
                disabled
              >
                Save & Next
              </Button>
              <Button
                color="success"
                type="button"
                onClick={() => props.goToNext()}
              >
                Next <i className="fa-solid fa-chevron-right" />
              </Button>
            </div> :
            <Button
              color="success"
              type="submit"
              onClick={() => handleSubmit}
            >
              Save & Next <i className="fa-solid fa-chevron-right" />
            </Button>}
        </div>
      </Form >
    </div>
  );
};

export default PersonalProfile;
