import { useFormik } from 'formik';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { Link, useHistory } from 'react-router-dom';
import { Alert, Button, Col, Form, Label, Row } from 'reactstrap';
import { AuthLayout, InputField } from 'src/components';
import { setFromErrors } from 'src/helpers';
import {
  useGetDataForRegistrationQuery,
  useRegistrationMutation,
} from 'src/rtk/features/auth/authApi';
import * as Yup from 'yup';
import RegistrationSuccessModal from './RegistrationSuccessModal';


const initialValues: Registration = {
  bmdc_registration_no: '',
  bmdc_registration_validity: '',
  bmdc_certificate: '',
  name: '',
  nid: '',
  registration_type: '',
  username: '',
  email: '',
  mobile: 0,
  password: '',
  password_confirmation: '',
  fellow_number: '',
};

const schemaForRegisteredUser = Yup.object({
  bmdc_registration_no: Yup.string().required('Registration No is required'),
  bmdc_registration_validity: Yup.date().required(
    'Registration validity is required',
  ),
  bmdc_certificate: Yup.mixed()
    .required('BMDC certificate is required')
    .test('is-pdf', 'File must be a PDF, PNG, JPG', (value) => {
      if (value) {
        return [
          'application/pdf',
          'image/jpeg',
          'image/png',
          'image/jpg',
        ].includes(value.type);
      }
      return true;
    })
    .test('file-size', 'File too large', (value) => {
      if (value) {
        return value.size <= FILE_SIZE;
      }
      return true;
    }),
  name: Yup.string().required('Name is required'),
  nid: Yup.string()
    .required('NID is required')
    .matches(/^\d+$/, 'Invalid NID')
    .max(17, 'Invalid Length')
    .min(10, 'Invalid Length').nullable(),
  registration_type: Yup.string().required('User type is required'),
  username: Yup.string().required('Username is required').trim(),
  email: Yup.string().email().required('Email is required').nullable(),
  mobile: Yup.string()
    .required('Mobile number is required')
    .max(14, 'Invalid mobile number')
    .matches(/^(?:\+88|88)?(01[0-9]\d{8})$/, 'Invalid phone number').nullable(),
  password: Yup.string()
    .min(6, 'Password must be 6 characters long')
    .required('Password is required')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{6,})/,
      'Must contain at least one uppercase, one lowercase, one numeric and one special character. Example: Abc@123',
    ),
  password_confirmation: Yup.string()
    .oneOf(
      [Yup.ref('password'), null],
      'Passwords and Confirm Password does not match',
    )
    .required('Password confirmation is required'),
  fellow_number: Yup.string().when(
    'registration_type',
    (registration_type, schema) => {
      if (registration_type === 'Fellow') {
        return schema.required('Fellow Number is Required!');
      }
      return schema;
    },
  ),
});

const schemaForUnregisteredUser = Yup.object({
  bmdc_registration_no: Yup.string().required('Registration No is required'),
  bmdc_registration_validity: Yup.date().required(
    'Registration validity is required',
  ),
  bmdc_certificate: Yup.mixed()
    .required('BMDC certificate is required')
    .test('is-pdf', 'File must be a PDF, PNG, JPG', (value) => {
      if (value) {
        return [
          'application/pdf',
          'image/jpeg',
          'image/png',
          'image/jpg',
        ].includes(value.type);
      }
      return true;
    })
    .test('file-size', 'File too large', (value) => {
      if (value) {
        return value.size <= FILE_SIZE;
      }
      return true;
    }),
  name: Yup.string().required('Name is required'),
  nid: Yup.string()
    .required('NID is required')
    .matches(/^\d+$/, 'Invalid NID')
    .max(17, 'Invalid Length')
    .min(10, 'Invalid Length').nullable(),
  registration_type: Yup.string().required('User type is required'),
  username: Yup.string().required('Username is required').trim(),
  email: Yup.string().email().required('Email is required').nullable(),
  mobile: Yup.string()
    .required('Mobile number is required')
    .max(14, 'Invalid mobile number')
    .matches(/^(?:\+88|88)?(01[0-9]\d{8})$/, 'Invalid phone number').nullable(),
  password: Yup.string()
    .min(6, 'Password must be 6 characters long')
    .required('Password is required')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{6,})/,
      'Must contain at least one uppercase, one lowercase, one numeric and one special character. Example: Abc@123',
    ),
  password_confirmation: Yup.string()
    .oneOf(
      [Yup.ref('password'), null],
      'Passwords and Confirm Password does not match',
    )
    .required('Password confirmation is required'),

  fellow_number: Yup.string().when(
    'registration_type',
    (registration_type, schema) => {
      if (registration_type === 'Fellow') {
        return schema.required('Fellow Number is Required!');
      }
      return schema;
    },
  ),
});

const FILE_SIZE = 1024 * 1024;

const Registration = () => {
  const [skip, setSkip] = useState(true);
  const [registrationNumber, setRegistrationNumber] = useState('');
  const [validRegistrationNumber, setValidRegistrationNumber] = useState('');
  const { data, isLoading } = useGetDataForRegistrationQuery(
    validRegistrationNumber,
    { skip },
  );

  const fileInputRef = useRef<any>();

  const [showPassword, setShowPassword] = useState(true);
  const [showConfirmPassword, setShowConfirmPassword] = useState(true);

  const [
    registration,
    {
      data: registrationData,
      isLoading: regLoading,
      isError,
      isSuccess,
      error,
    },
  ] = useRegistrationMutation();

  const err: CustomError = error as CustomError;
  const [disableField, setDisableField] = useState(false);

  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    setFieldValue,
    setValues,
    resetForm,
    setErrors,
    getFieldProps,
  } = useFormik({
    initialValues,
    validationSchema: disableField
      ? schemaForRegisteredUser
      : schemaForUnregisteredUser,
    onSubmit: (values: any) => {
      values.bmdc_registration_validity = moment(
        values.bmdc_registration_validity,
      ).format('YYYY-MM-DD');

      const formData = new FormData();
      Object.keys(values).forEach((key) => {
        formData.append(key, values[key]);
      });

      registration(formData);
    },
  });

  useEffect(() => {
    if ((data?.data === 0 && !isLoading) || data?.data) {
      setValues({
        ...values,
        ...data?.data,
        bmdc_registration_validity: data?.data?.bmdc_registration_validity,
      });
    }

    if (!skip) {
      if (data?.success === false) {
        setDisableField(false);
        resetForm({
          values: {
            bmdc_registration_no: values.bmdc_registration_no,
            bmdc_registration_validity: '',
            bmdc_certificate: '',
            name: '',
            nid: '',
            registration_type: '',
            username: values.bmdc_registration_no,
            email: '',
            mobile: 0,
            password: '',
            password_confirmation: '',
            fellow_number: '',
          },
        });
        fileInputRef.current.value = '';
      } else {
        setDisableField(true);
      }
    }
  }, [data]);

  // useEffect(() => {
  //   const debounced = debounce((value) => {
  //     if (values.bmdc_registration_no) {
  //       setSkip(false);
  //       setRegistrationNumber(value);
  //     }
  //   }, 500);
  //   debounced(values.bmdc_registration_no);
  //   setFieldValue('username', values.bmdc_registration_no);
  // }, [values.bmdc_registration_no]);
  const [modalShow, setModalShow] = useState(false);

  const history = useHistory();
  useEffect(() => {
    if (isSuccess) {
      toast.success(`Username-${values?.username} Registered Successfully`);
      setModalShow(true)

    }
    if (isError) {
      toast.error(err?.message || 'Something went wrong');
      setFromErrors(err.data, setErrors);
    }
  }, [isSuccess, isError]);

  useEffect(() => {
    setValidRegistrationNumber(registrationNumber);
  }, [registrationNumber]);

  const handleBlur = () => {
    if (values.bmdc_registration_no) {
      setSkip(false);
      setRegistrationNumber(values.bmdc_registration_no);
    }
    setFieldValue('username', values.bmdc_registration_no);
  };
  return (
    <AuthLayout>
      <Row>
        <Form onSubmit={handleSubmit} encType="multipart/form-data">
          <Row>
            <Col sm={12} md={6} lg={6}>
              <div className="mb-3">
                <Label className="form-label required-field">
                  BMDC Reg. No{' '}
                  <i
                    className="fas fa-info-circle"
                    title="Enter your valid BMDC Reg. No."
                  ></i>
                </Label>
                <InputField
                  name="bmdc_registration_no"
                  type="text"
                  placeholder="Enter BMDC Reg. No"
                  value={values.bmdc_registration_no}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  invalid={{ errors, touched }}
                />
              </div>
            </Col>

            {/* {disableField ? (
              <Col sm={12} md={6} lg={6}>
                <div className="mb-3">
                  <Label className="form-label required-field">Name</Label>
                  <InputField
                    name="name"
                    type="text"
                    placeholder="Enter Name"
                    value={values.name}
                    onChange={handleChange}
                    invalid={{ errors, touched }}
                    readOnly
                  />
                </div>
              </Col>
            ) : ( */}
            <Col sm={12} md={6} lg={6}>
              <div className="mb-3">
                <Label className="form-label required-field">Name</Label>
                <InputField
                  name="name"
                  type="text"
                  placeholder="Enter Name"
                  value={values.name}
                  onChange={handleChange}
                  disabled={data?.data?.name}
                  invalid={{ errors, touched }}
                />
              </div>
            </Col>
            {/* )} */}


            {/* {disableField ? (
              <Col sm={12} md={6} lg={6}>
                <div className="mb-3">
                  <Label className="form-label required-field">BMDC Reg. Validity</Label>
                  <InputField
                    type="date"
                    placeholder="Enter BMDC Reg. Validity"
                    readOnly
                    {...getFieldProps('bmdc_registration_validity')}
                    invalid={{ errors, touched }}
                  />
                </div>
              </Col>
            ) : ( */}
            <Col sm={12} md={6} lg={6}>
              <div className="mb-3">
                <Label className="form-label required-field">
                  BMDC Reg. Validity
                </Label>
                <InputField
                  type="date"
                  placeholder="Enter BMDC Reg. Validity"
                  disabled={data?.data?.bmdc_registration_validity}
                  {...getFieldProps('bmdc_registration_validity')}
                  invalid={{ errors, touched }}
                />
              </div>
            </Col>
            {/* )} */}

            <Col sm={12} md={6} lg={6}>
              <div className="mb-3">
                <Label className="form-label required-field">
                  BMDC Certificate{' '}
                  <span className="text-muted fw-light">
                    (Format: PDF, JPG, PNG, Size: &lt; 1MB)
                  </span>
                </Label>
                <input
                  ref={fileInputRef}
                  name="bmdc_certificate"
                  type="file"
                  className="form-control"
                  placeholder="Upload Document"
                  onChange={(e: any) => {
                    setFieldValue('bmdc_certificate', e.target.files[0]);
                  }}
                ></input>
                {errors.bmdc_certificate && touched.bmdc_certificate ? (
                  <div className="text-danger">{errors.bmdc_certificate as string}</div>
                ) : null}
              </div>
            </Col>


            {/* {disableField ? (
              <Col sm={12} md={6} lg={6}>
                <div className="mb-3">
                  <Label className="form-label required-field">Mobile</Label>
                  <InputField
                    name="mobile"
                    type="text"
                    placeholder="Enter Phone"
                    value={values.mobile}
                    onChange={handleChange}
                    invalid={{ errors, touched }}
                    readOnly
                  />
                </div>
              </Col>
            ) : ( */}
            <Col sm={12} md={6} lg={6}>
              <div className="mb-3">
                <Label className="form-label required-field">Mobile</Label>
                <InputField
                  name="mobile"
                  type="text"
                  placeholder="Enter Phone"
                  value={values.mobile ?? ''}
                  disabled={data?.data?.mobile}
                  onChange={handleChange}
                  invalid={{ errors, touched }}
                />
              </div>
            </Col>
            {/* )} */}

            {/* {disableField ? (
              <Col sm={12} md={12} lg={12}>
                <div className="mb-3">
                  <Label className="form-label required-field">Email Address</Label>
                  <InputField
                    name="email"
                    type="email"
                    placeholder="Enter Email Address"
                    value={values.email}
                    onChange={handleChange}
                    invalid={{ errors, touched }}
                    readOnly
                  />
                </div>
              </Col>
            ) : ( */}
            <Col sm={12} md={6} lg={6}>
              <div className="mb-3">
                <Label className="form-label required-field">
                  Email Address
                </Label>
                <InputField
                  name="email"
                  type="email"
                  placeholder="Enter Email Address"
                  value={values.email ?? ''}
                  disabled={data?.data?.email}
                  onChange={handleChange}
                  invalid={{ errors, touched }}
                />
              </div>
            </Col>
            {/* )} */}

            <Col sm={12} md={6} lg={6}>
              <div className="mb-3">
                <Label className="form-label required-field">User Type</Label>
                <InputField
                  name="registration_type"
                  type="select"
                  onChange={handleChange}
                  value={values.registration_type}
                  options={[
                    { value: 'Trainee', label: 'Trainee' },
                    { value: 'Supervisor', label: 'Supervisor' },
                    { value: 'Fellow', label: 'Fellow' },
                  ]}
                  invalid={{ errors, touched }}
                />
              </div>
            </Col>

            {/* {disableField ? (
              <Col sm={12} md={6} lg={6}>
                <div className="mb-3">
                  <Label className="form-label required-field">NID</Label>
                  <InputField
                    name="nid"
                    type="text"
                    placeholder="Enter NID"
                    value={values.nid}
                    onChange={handleChange}
                    invalid={{ errors, touched }}
                    readOnly
                  />
                </div>
              </Col>
            ) : ( */}
            <Col sm={12} md={6} lg={6}>
              <div className="mb-3">
                <Label className="form-label required-field">NID</Label>
                <InputField
                  name="nid"
                  type="text"
                  placeholder="Enter NID"
                  value={values.nid ?? ''}
                  disabled={data?.data?.nid}
                  onChange={handleChange}
                  invalid={{ errors, touched }}
                />
              </div>
            </Col>
            {/* )} */}
          </Row>

          <Row>
            <Col sm={12} md={6} lg={6}>
              <div className="mb-3">
                <Label className="form-label required-field">Username</Label>
                <InputField
                  name="username"
                  type="text"
                  placeholder="Enter Username"
                  value={values.username}
                  onChange={handleChange}
                  invalid={{ errors, touched }}
                  readOnly={true}
                />
              </div>
            </Col>
            {values.registration_type === 'Supervisor' ||
              values.registration_type === 'Fellow' ? (
              <Col>
                <div>
                  {values.registration_type === 'Fellow' ? <Label className="form-label required-field">Fellow No</Label> : <Label className="form-label">Fellow No</Label>}
                  <InputField
                    name="fellow_number"
                    type="text"
                    placeholder="Enter Fellow Number"
                    value={values.fellow_number}
                    onChange={handleChange}
                    invalid={{ errors, touched }}
                  />
                </div>
              </Col>
            ) : null}
          </Row>
          <Row>
            <Col sm={12} md={6} lg={6}>
              <div className="mb-3">
                <Label className="form-label required-field">Password</Label>
                <div className="input-group password-input">
                  <InputField
                    name="password"
                    type={showPassword ? 'password' : 'text'}
                    placeholder="Enter Password"
                    value={values.password}
                    onChange={handleChange}
                    invalid={{ errors, touched }}
                  />
                  <Button
                    color="light"
                    size="sm"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    <i
                      className={`${showPassword ? 'fa fa-eye' : 'fa fa-eye-slash'
                        }`}
                    />
                  </Button>
                  <div className='card mt-4'>
                    <ul>
                      <li>Password must be at least six characters long</li>
                      <li>Password must contain at least one uppercase and one lowercase</li>
                      <li>Password must contain at least one numeric value</li>
                      <li>Password must contain at least one special character</li>
                      <li>Example : Aa@12345</li>
                    </ul>
                  </div>
                </div>
              </div>
            </Col>
            <Col sm={12} md={6} lg={6}>
              <div className="mb-3">
                <Label className="form-label required-field">
                  Confirm Password
                </Label>
                <div className="input-group password-input">
                  <InputField
                    name="password_confirmation"
                    type={showConfirmPassword ? 'password' : 'text'}
                    placeholder="Enter Confirm Password"
                    value={values.password_confirmation}
                    onChange={handleChange}
                    invalid={{ errors, touched }}
                  />
                  <Button
                    color="light"
                    size="sm"
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  >
                    <i
                      className={`${showConfirmPassword ? 'fa fa-eye' : 'fa fa-eye-slash'
                        }`}
                    />
                  </Button>
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col sm={12}>
              <div className="mt-3 d-grid justify-content-center">
                <button
                  className="btn btn-primary btn-block d-flex  align-items-center"
                  type="submit"
                  disabled={regLoading}
                >
                  {!regLoading && <span className='px-5'>Register</span>}

                  {regLoading && (
                    <>
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      />
                      <span className="px-5">Registering...</span>
                    </>
                  )}
                </button>
              </div>

              {isError ? (
                <Alert className="mt-3" color="danger">
                  {err.message}
                </Alert>
              ) : null}

              <div className="text-center mt-4 mb-3">
                <p className="my-3">
                  Already have account?
                  <Link to="/login" className="btn btn-sm btn-outline-primary fw-semibold mx-1">
                    Login
                  </Link>
                  here.
                </p>
              </div>
            </Col>
          </Row>
        </Form>

        {modalShow ? (
          <RegistrationSuccessModal
            isOpen={modalShow}
            toggle={() => setModalShow(!modalShow)}
            username={values?.username}
            name={values?.name}
          />
        ) : null}
      </Row>
    </AuthLayout>
  );
};

export default Registration;
