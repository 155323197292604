import { useFormik } from 'formik';
import moment from 'moment';
import { useEffect, useState } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import { Button, Card, CardBody, Container, Form, FormGroup, Label } from 'reactstrap';
import { InputField } from 'src/components';
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';
import { actionColumnStyles, dataTableCustomStyles } from 'src/components/Common/FixedActionColumn';
import { useGetCourseApplicantionListQuery } from 'src/rtk/features/courseApproval/courseApprovalApi';
import loader from '../../assets/images/loader.gif';
import CourseApprovalViewModal from './CourseApprovalViewModal';
import ProfileViewModal from './ProfileViewModal';
import SlotApprovalViewModal from './SlotApprovalViewModal';

const CourseApprovalList = () => {
    const [modalShow, setModalShow] = useState(false);
    const [courseInfoModal, setCourseInfoModal] = useState(false);
    const [slotInfoModal, setSlotInfoModal] = useState(false);
    const [approvalId, setApprovalId] = useState('');
    let referenceUrl = new URLSearchParams(location.search).get('ref');

    const allStatus: SelectOption[] = [
        { value: 'Approve', label: 'Approve' },
        { value: 'Pending', label: 'Pending' },
        { value: 'Reject', label: 'Reject' },
    ];

    const [page, setPage] = useState<any>(1);
    const [limit, setLimit] = useState<any>(10);
    const [search, setSearch] = useState('');

    const generateQueryUrl = () => {
        return `page=${page}&per_page=${limit}${search}`;
    };

    const handlePageChange = (page: number) => {
        setPage(page);
    };

    const handlePerRowsChange = (newPerPage: number, page: number) => {
        setLimit(newPerPage);
        setPage(page);
    };

    const {
        data: approvalList,
        isSuccess,
        isLoading,
        isFetching,
        refetch,
    } = useGetCourseApplicantionListQuery(generateQueryUrl());

    useEffect(() => {
        refetch();
    }, [referenceUrl]);

    interface SearchFields {
        search?: string;
        start_date?: string;
        end_date?: string;
        approved_status?: string;
        bmdc_registration_no?: string;
    }

    const initialValues: SearchFields = {
        search: '',
        start_date: '',
        end_date: '',
        approved_status: '',
        bmdc_registration_no: '',
    };

    const {
        handleSubmit,
        getFieldProps,
        handleChange,
        handleBlur,
        values,
        touched,
        errors,
        resetForm,
        setFieldValue,
        setErrors,
    } = useFormik({
        initialValues,

        onSubmit: (values: any) => {
            let s: string = '';

            if (values.search !== '') {
                s = s + `&search=${values.search}`;
            }

            if (values.start_date !== '') {
                s = s + `&start_date=${values.start_date}`;
            }

            if (values.end_date !== '') {
                s = s + `&end_date=${values.end_date}`;
            }

            if (values.approved_status !== '') {
                s = s + `&approved_status=${values.approved_status}`;
            }
            if (values.bmdc_registration_no !== '') {
                s = s + `&bmdc_registration_no=${values.bmdc_registration_no}`;
            }

            setSearch(s);
            setPage('1');
            console.log(values);
        },
    });

    const columns: TableColumn<any>[] = [
        {
            name: <div>Sl. No.</div>,
            width: '60px',
            cell: (row, index) => <strong>{((page - 1) * limit + index) + 1}</strong>,
        },
        {
            name: <div>Apply Date</div>,
            width: '110px',
            selector: (row) => row?.created_at,
            cell: (row, index) =>
                row.created_at ? moment(row?.created_at).format('DD-MMM-YYYY') : '',
        },

        {
            name: <div>Full Name</div>,
            width: '140px',
            selector: (row) => row?.profile_info?.personal_info?.full_name,
            wrap: true,
        },

        {
            name: <div>BMDC Reg. No.</div>,
            selector: (row) => row?.profile_info?.bmdc_registration_no,
            wrap: true,
        },
        {
            name: <div>Reg. Type</div>,
            selector: (row) => row?.profile_info?.registration_type,
            wrap: true,
        },

        {
            name: <div>Course Name</div>,
            width: '110px',
            selector: (row) => row?.exam_name,
            wrap: true,
        },

        {
            name: <div>Subject</div>,
            width: '110px',
            selector: (row) => row?.subject_name,
            wrap: true,
        },

        {
            name: <div>FCPS Part-1 Session</div>,
            width: '110px',
            selector: (row) => row?.profile_info?.current_session_and_supervisor_info?.session_name,
            wrap: true,
        },

        {
            name: <div>Supervisor</div>,
            width: '130px',
            selector: (row) => row?.profile_info?.current_session_and_supervisor_info?.supervisor?.name,
            wrap: true,
        },

        {
            name: <div>Supervisor Approval Status</div>,
            width: '110px',
            selector: (row) => row?.supervisors_approve_status,
            cell: (row) => {
                switch (row?.supervisors_approve_status) {
                    case 'Reject':
                        return (
                            <>
                                <div className="badge rounded-pill d-inline-block badge-rejected font-size-12 fw-medium">
                                    {'Rejected'}
                                </div>
                            </>
                        );

                    case 'Draft':
                        return (
                            <>
                                <div className="badge rounded-pill d-inline-block badge-draft font-size-12 fw-medium">
                                    {row?.supervisors_approve_status}
                                </div>
                            </>
                        );

                    case 'Approve':
                        return (
                            <>
                                <div className="badge rounded-pill d-inline-block badge-approved font-size-12 fw-medium">
                                    {'Approved'}
                                </div>
                            </>
                        );

                    case 'Pending':
                        return (
                            <>
                                <div className="badge rounded-pill d-inline-block badge-pending font-size-12 fw-medium">
                                    {row?.supervisors_approve_status}
                                </div>
                            </>
                        );

                    default:
                        return (
                            <>
                                <div className="badge rounded-pill d-inline-block badge-pending font-size-12 fw-medium">
                                    {row?.supervisors_approve_status}
                                </div>
                            </>
                        );
                }
            },
            wrap: true,
        },
        {
            name: <div>Course Payment Approval Status by Admin</div>,
            selector: (row) => row?.profile_info?.order_master?.payment_verification_status,
            width: '140px',
            cell: (row) => {
                switch (row?.profile_info?.order_master?.payment_verification_status) {
                    case 'Reject':
                        return (
                            <>
                                <div className="badge rounded-pill d-inline-block badge-rejected font-size-12 fw-medium">
                                    {'Rejected'}
                                </div>
                            </>
                        );

                    case 'Draft':
                        return (
                            <>
                                <div className="badge rounded-pill d-inline-block badge-draft font-size-12 fw-medium">
                                    {row?.profile_info?.order_master?.payment_verification_status}
                                </div>
                            </>
                        );

                    case 'Approved':
                        return (
                            <>
                                <div className="badge rounded-pill d-inline-block badge-approved font-size-12 fw-medium">
                                    {'Approved'}
                                </div>
                            </>
                        );

                    case 'Pending':
                        return (
                            <>
                                <div className="badge rounded-pill d-inline-block badge-pending font-size-12 fw-medium">
                                    {row?.profile_info?.order_master?.payment_verification_status}
                                </div>
                            </>
                        );

                    default:
                        return (
                            <>
                                <div className="badge rounded-pill d-inline-block badge-pending font-size-12 fw-medium">
                                    {row?.profile_info?.order_master?.payment_verification_status}
                                </div>
                            </>
                        );
                }
            },
        },
        {
            name: <div>Action</div>,
            style: actionColumnStyles,
            width: '240px',
            cell: (row, index) => (
                <div className='d-flex gap-1 my-1'>
                    <Button
                        className="btn btn-sm btn-info"
                        onClick={() => modalShowHandler(true, row?.id)}
                    >
                        Profile
                    </Button>

                    <Button
                        className="btn btn-sm btn-success"
                        onClick={() => courseModalShowHandler(true, row?.id)}
                    >
                        Course
                    </Button>

                    <Button
                        className="btn btn-sm btn-warning"
                        onClick={() => slotModalShowHandler(true, row?.id)}
                        disabled={row?.supervisors_approve_status !== 'Approve'}
                    >
                        Slot
                    </Button>
                    {modalShow && approvalId === row?.id ? (
                        <ProfileViewModal
                            isOpen={modalShow}
                            toggle={() => setModalShow(!modalShow)}
                            info={row}
                            id={row.registration_id!}
                        />
                    ) : null}

                    {courseInfoModal && approvalId === row?.id ? (
                        <CourseApprovalViewModal
                            isOpen={courseInfoModal}
                            toggle={() => setCourseInfoModal(!courseInfoModal)}
                            info={row}
                            id={row.registration_id!}
                        />
                    ) : null}

                    {slotInfoModal && approvalId === row?.id ? (
                        <SlotApprovalViewModal
                            isOpen={slotInfoModal}
                            toggle={() => setSlotInfoModal(!slotInfoModal)}
                            info={row}
                            id={row.registration_id!}
                        />
                    ) : null}
                </div>
            ),
        },
    ];

    const modalShowHandler = (modalState: boolean, id: string) => {
        setModalShow(modalState);
        setApprovalId(id);
    }

    const courseModalShowHandler = (modalState: boolean, id: string) => {
        setCourseInfoModal(modalState);
        setApprovalId(id);
    }

    const slotModalShowHandler = (modalState: boolean, id: string) => {
        setSlotInfoModal(modalState);
        setApprovalId(id);
    }

    return (
        <div className="page-content">
            {isFetching ? (
                <div className="overlay">
                    <img src={loader} alt="Loading" height={100} width={300} />
                </div>
            ) : null}
            <Container fluid>
                <BetterBreadcrumb
                    breadcrumbItem={[{ link: '#', name: 'Admin' }]}
                    title={'Course Application Approval'}
                />
                <Card>
                    <CardBody>
                        <Form
                            className="custom-form"
                            onSubmit={(e) => {
                                e.preventDefault();
                                handleSubmit();
                            }}
                            onReset={(e) => {
                                e.preventDefault();
                                handleSubmit();
                                resetForm();
                            }}
                        >
                            <div className="filter-wrapper">
                                <FormGroup>
                                    <Label for="bmdc_registration_no">BMDC Reg. No</Label>
                                    <InputField
                                        type="text"
                                        placeholder="BMDC Reg. No"
                                        {...getFieldProps('bmdc_registration_no')}
                                    />
                                </FormGroup>


                                <FormGroup>
                                    <Label for="start_date">From Date</Label>
                                    <InputField
                                        type="date"
                                        {...getFieldProps('start_date')}
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <Label for="end_date">To Date</Label>
                                    <InputField
                                        type="date"
                                        {...getFieldProps('end_date')}
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <Label for="approved_status">Status</Label>
                                    <InputField
                                        type="select"
                                        placeholder="Status"
                                        options={allStatus}
                                        {...getFieldProps('approved_status')}
                                    />
                                </FormGroup>

                                <FormGroup className="btn-column mb-3">
                                    <Button color="danger" type="reset" >Reset</Button>
                                    <Button type="submit" color='primary'>Search</Button>
                                </FormGroup>
                            </div>
                        </Form>

                        <DataTable
                            columns={columns}
                            data={approvalList?.data?.data!}
                            customStyles={dataTableCustomStyles}
                            pagination
                            paginationServer
                            progressPending={isLoading}
                            expandOnRowClicked
                            highlightOnHover
                            onChangePage={handlePageChange}
                            onChangeRowsPerPage={handlePerRowsChange}
                            paginationTotalRows={approvalList?.data?.total}
                            paginationRowsPerPageOptions={[2, 5, 10, 20, 50, 100, 200]}
                            responsive
                            persistTableHead={true}
                        />
                    </CardBody>
                </Card>
            </Container>
        </div>
    );
};

export default CourseApprovalList;
