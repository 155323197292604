import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Label,
  Modal,
  Row
} from 'reactstrap';
import { Button, InputField } from 'src/components';
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';
import { config } from 'src/config';
import { useAppSelector } from 'src/rtk/app/hook';
import { useGetReportListQuery, useLazyGetProgressReportDataQuery } from 'src/rtk/features/logbookMonitoringReport/logbookMonitoringReportApi';
import { useGetSubjectInfosQuery } from 'src/rtk/features/setup/subjectInfo/subjectInfoApi';
import loader from '../../assets/images/loader.gif';
import { AdminProgressReportPDF } from './AdminProgressReportPDF';
import { ProgressReportPDF } from './ProgressReportPDF';

const ProgressReport = () => {
  const [pageShow, setPageShow] = useState(false);
  const authUser = useAppSelector((state) => state.auth.user);

  const [page, setPage] = useState<any>(1);
  const [limit, setLimit] = useState<any>(10);
  const [search, setSearch] = useState('');
  const [selectedSubject, setSelectedSubject] = useState<any>('');
  const [selectedSlotType, setSelectedSlotType] = useState<any>('');
  const [currentTraineeStatus, setCurrentTraineeStatus] = useState<boolean>(false);
  const [reportId, setReportId] = useState('');
  const [sessionId, setSessionId] = useState('');
  const [sessionInfo, setSessionInfo] = useState<any>('');
  const [regInfo, setRegInfo] = useState<any>('');
  const [modalShow, setModalShow] = useState(false);
  let referenceUrl = new URLSearchParams(location.search).get('ref');

  const generateQueryUrl = () => {
    return `page=${page}&paginate_per_page=${limit}${search}`;
  };

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  const handlePerRowsChange = (newPerPage: number, page: number) => {
    setLimit(newPerPage);
    setPage(page);
  };

  const {
    data: reportList,
    isLoading,
    isSuccess,
    error,
    isFetching,
    refetch,
  } = useGetReportListQuery(generateQueryUrl());

  useEffect(() => {
    refetch();
  }, [referenceUrl]);

  const data: any = reportList?.data?.data || [];

  console.log(data);

  const [getProgressReportData, { data: list }] = useLazyGetProgressReportDataQuery();

  useEffect(() => {
    if (modalShow) {
      getProgressReportData(regInfo?.registration_no);
    }
  }, [modalShow]);

  const sessionNameList = list?.data || [];
  console.log(sessionNameList);

  const err = error as CustomError;
  interface SearchFields {
    search?: string;
    bmdc_registration_no?: string;
    subject?: string;
    slot_type?: string;
    current_trainee?: number;
  }

  const searchInitialValues: SearchFields = {
    search: '',
    bmdc_registration_no: '',
    subject: '',
    slot_type: '',
    current_trainee: 0,
  };

  const { data: subjects } = useGetSubjectInfosQuery();

  const allSubjects: SelectOption[] =
    subjects?.data?.map((subject) => ({
      value: subject.subject_name,
      label: subject.subject_name,
    })) || [];

  const {
    handleSubmit: searchSubmit,
    getFieldProps: searchGetFieldProps,
    values: searchValues,
    touched,
    errors,
    setFieldValue,
    resetForm: searchResetForm,
    // setErrors,
    // isSubmitting,
  } = useFormik({
    initialValues: searchInitialValues,

    onSubmit: (searchValues) => {
      let s: string = '';

      if (searchValues.search && searchValues.search !== '') {
        s = s + `&search=${searchValues.search}`;
      }

      if (searchValues.bmdc_registration_no && searchValues.bmdc_registration_no !== '') {
        s = s + `&bmdc_registration_no=${searchValues.bmdc_registration_no}`;
      }

      if (searchValues.subject && searchValues.subject !== '') {
        s = s + `&subject=${searchValues.subject}`;
      }

      if (searchValues.slot_type && searchValues.slot_type !== '') {
        s = s + `&slot_type=${searchValues.slot_type}`;
      }

      s = s + `&current_trainee=${searchValues.current_trainee}`

      setSearch(s);
      setPage('1');
      // setLimit(20);
    },
  });

  const evaluationShowHandler = (pageState: boolean) => {
    setPageShow(pageState);
  }
  const modalShowHandler = (pageState: boolean, infos: any) => {
    setModalShow(pageState);
    setRegInfo(infos);
  }

  useEffect(() => {
    if (selectedSubject !== '') {
      setFieldValue('subject', selectedSubject?.value);
    }
  }, [selectedSubject]);

  useEffect(() => {
    if (selectedSlotType !== '') {
      setFieldValue('slot_type', selectedSlotType?.value);
    }
  }, [selectedSlotType]);

  useEffect(() => {
    if (currentTraineeStatus) {
      setFieldValue('current_trainee', 1);
    } else {
      setFieldValue('current_trainee', 0);
    }
  }, [currentTraineeStatus]);

  const handleCurrentTraineeStatus = (e: any) => {
    if (e.target.checked) {
      setCurrentTraineeStatus(true);
    } else {
      setCurrentTraineeStatus(false);
    }
  };

  const columns: TableColumn<any>[] = [
    {
      name: '#',
      width: '50px',
      cell: (row, index) => <strong>{((page - 1) * limit + index) + 1}</strong>,
    },
    // {
    //   name: <div>Photo</div>,
    //   selector: (row) => row?.personal_info.picture,
    //   cell: (row, index) => (
    //     <>
    //       {row?.personal_info?.picture ? (
    //         <img
    //           src={config.APP_URL + row?.personal_info?.picture}
    //           alt="Profile Photo"
    //           data-holder-rendered="true"
    //           className="img-thumbnail my-1 rounded-circle avatar-md"
    //         />
    //       ) : (
    //         ''
    //       )}
    //     </>
    //   ),
    // },

    {
      name: <div>Full Name</div>,
      selector: (row) => row?.personal_info?.full_name,
    },

    {
      name: <div>BMDC Reg. No.</div>,
      selector: (row) => row?.bmdc_registration_no,
    },
    {
      name: <div>Reg. Type</div>,
      selector: (row) => row?.registration_type,
    },
    {
      name: <div>Subject Name</div>,
      selector: (row) => row?.subject?.subject_name,
    },

    {
      name: <div>Action</div>,
      cell: (row, index) => (
        <div className='my-1'>
          {authUser?.type === 'Operation' ?
            <button
              className="btn btn-sm btn-primary me-1"
              disabled={row?.current_session_and_supervisor_info?.progress_report?.submit_status !== 'Submit'}
              onClick={() => {
                modalShowHandler(true, row);
              }}
            >
              <span className="fas fa-eye"></span> View
            </button>
            :
            <button
              className="btn btn-sm btn-primary me-1"
              disabled={row?.current_session_and_supervisor_info?.progress_report?.submit_status !== 'Submit'}
              onClick={() => {
                setReportId(row.user_id);
              }}
            >
              <span className="fas fa-eye"></span> View
            </button>}
          {reportId === row.user_id ? (
            <ProgressReportPDF userInfo={row} setReportId={setReportId} />
          ) : null}

          {authUser?.type === 'Supervisor' ? <Link to={{ pathname: `/performance-evaluation/${row?.registration_no}/${row?.current_session_and_supervisor_info?.supervisor_id}/${row?.current_session_and_supervisor_info?.supervisor?.name}/${row?.current_session_and_supervisor_info?.exam_training_id}` }}
            className={`btn btn-danger btn-sm ${row?.current_session_and_supervisor_info?.progress_report?.submit_status === 'Submit' ? 'disabled' : ''}`}>
            Evaluation
          </Link> : null}
        </div>
      ),
    },
  ];

  document.title = 'Progress Report | BCPS';

  return (
    <div className="page-content">
      {isLoading || isFetching ? (
        <div className="overlay">
          <img src={loader} alt="Loading" height={100} width={300} />
        </div>
      ) : null}
      <Container fluid>
        <BetterBreadcrumb
          title="Progress Report"
          breadcrumbItem={[
            { link: '#', name: 'Monitoring' },
          ]}
        />
        <Card>
          <CardBody>
            <Row>
              <Col>
                <div className="gap-2 ">
                  <Form
                    className="custom-form"
                    onSubmit={(e) => {
                      e.preventDefault();
                      searchSubmit();
                    }}
                  >
                    <div className="filter-wrapper mb-0">
                      <FormGroup>
                        <Label for="bmdc_registration_no">BMDC Reg. No.</Label>
                        <InputField
                          type="text"
                          placeholder="BMDC Reg. No."
                          className="form-control"
                          {...searchGetFieldProps('bmdc_registration_no')}
                        />
                      </FormGroup>

                      <FormGroup>
                        <Label for="subject">Subject</Label>
                        <Select
                          isClearable={true}
                          isSearchable={true}
                          value={selectedSubject}
                          onChange={setSelectedSubject}
                          options={allSubjects}
                          placeholder='Select...'
                          name="subject"
                        />
                      </FormGroup>

                      <FormGroup>
                        <Label for="slot_type">Slot Type</Label>
                        <Select
                          isClearable={true}
                          isSearchable={true}
                          value={selectedSlotType}
                          onChange={setSelectedSlotType}
                          options={[{ label: 'Core', value: 'Core' }, { label: 'Specialty', value: 'Advance' }]}
                          placeholder='Select...'
                          name="slot_type"
                        />
                      </FormGroup>

                      <FormGroup>
                        <Label check className='form-check-label'>
                          <input
                            type="checkbox"
                            name="current_trainee"
                            id="current_trainee"
                            className="form-check-input"
                            checked={currentTraineeStatus}
                            onChange={(e) => {
                              handleCurrentTraineeStatus(e);
                            }}
                          /> Current Trainee
                        </Label>
                      </FormGroup>

                      <FormGroup className="btn-column mb-3">
                        <Button
                          color="danger"
                          type="reset"
                          text="Reset"
                          onClick={() => {
                            setSelectedSubject('');
                            setSelectedSlotType('');
                            setCurrentTraineeStatus(false);
                            searchResetForm();
                            searchSubmit();
                          }}
                        />

                        <Button
                          type="button"
                          text="Search"
                          onClick={() => {
                            searchSubmit();
                          }}
                        />
                      </FormGroup>
                    </div>
                  </Form>
                </div>
              </Col>
            </Row>
            <DataTable
              columns={columns}
              data={data ? data : []}
              pagination
              paginationServer
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handlePerRowsChange}
              progressPending={isLoading}
              paginationTotalRows={reportList?.data?.total}
              expandOnRowClicked
              highlightOnHover
              paginationRowsPerPageOptions={[2, 5, 10, 20, 50, 100, 200]}
              persistTableHead={true}
            />
          </CardBody>
        </Card>
      </Container>
      <Modal
        onOpened={function () {
          toast.dismiss();
        }}
        isOpen={modalShow}
        toggle={() => {
          document.body.classList.add('no_padding');
        }}
        backdrop={'static'}
        modalTransition={{ timeout: 0 }}
        backdropTransition={{ timeout: 0 }}
      >
        <div className="modal-header">
          <h5 className="modal-title" id="staticBackdropLabel">
            Select Session
          </h5>
          <button
            type="button"
            className="btn-close"
            aria-label="Close"
            onClick={() => {
              setModalShow(!modalShow);
            }}
          ></button>
        </div>
        <div className="modal-body">
          <Row>
            <>
              <Col sm={12}>
                <table className='table table-bordered'>
                  <tbody>
                    {sessionNameList?.map((item: any) => (
                      <tr key={item.id}>
                        <td><b>{item?.session?.name}</b></td>
                        <td>
                          <button
                            className="btn btn-sm btn-info"
                            onClick={() => {
                              setSessionInfo(item);
                              setSessionId(item?.id);
                            }}
                          >
                            <span className="fas fa-eye"></span> Progress Report
                          </button>
                          {sessionId === item?.id ? (
                            <AdminProgressReportPDF userInfo={regInfo} sessionInfo={sessionInfo} setReportId={setSessionId} />
                          ) : null}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </Col>
            </>
          </Row>
        </div>
      </Modal>
    </div>
  );
};

export default ProgressReport;
