import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import Select from 'react-select';
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Label,
  Row
} from 'reactstrap';
import { Button, InputField } from 'src/components';
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';
import { config } from 'src/config';
import { useGetReportListQuery } from 'src/rtk/features/logbookMonitoringReport/logbookMonitoringReportApi';
import { useGetSubjectInfosQuery } from 'src/rtk/features/setup/subjectInfo/subjectInfoApi';
import loader from '../../assets/images/loader.gif';
import { SixMonthlyReportPDF } from './SixMonthlyReportPDF';

const TraineeListForSixMonthReport = () => {

  const [page, setPage] = useState<any>(1);
  const [limit, setLimit] = useState<any>(10);
  const [search, setSearch] = useState('');
  const [selectedSubject, setSelectedSubject] = useState<any>('');
  const [reportId, setReportId] = useState('');
  let referenceUrl = new URLSearchParams(location.search).get('ref');

  const generateQueryUrl = () => {
    return `page=${page}&paginate_per_page=${limit}${search}`;
  };

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  const handlePerRowsChange = (newPerPage: number, page: number) => {
    setLimit(newPerPage);
    setPage(page);
  };

  const {
    data: reportList,
    isLoading,
    isSuccess,
    error,
    isFetching,
    refetch
  } = useGetReportListQuery(generateQueryUrl());

  useEffect(() => {
    refetch();
  }, [referenceUrl]);

  const data: any = reportList?.data?.data || [];

  const err = error as CustomError;
  interface SearchFields {
    search?: string;
    bmdc_registration_no?: string;
    subject?: string;
  }

  const searchInitialValues: SearchFields = {
    search: '',
    bmdc_registration_no: '',
    subject: '',
  };

  const { data: subjects } = useGetSubjectInfosQuery();

  const allSubjects: SelectOption[] =
    subjects?.data?.map((subject) => ({
      value: subject.id!,
      label: subject.subject_name,
    })) || [];

  const {
    handleSubmit: searchSubmit,
    getFieldProps: searchGetFieldProps,
    values: searchValues,
    touched,
    errors,
    setFieldValue,
    resetForm: searchResetForm,
    // setErrors,
    // isSubmitting,
  } = useFormik({
    initialValues: searchInitialValues,

    onSubmit: (searchValues) => {
      let s: string = '';

      if (searchValues.search !== '') {
        s = s + `&search=${searchValues.search}`;
      }

      if (searchValues.bmdc_registration_no !== '') {
        s = s + `&bmdc_registration_no=${searchValues.bmdc_registration_no}`;
      }

      if (searchValues.subject !== '') {
        s =
          s +
          `&subject=${allSubjects.find((r) => r.value === searchValues.subject)?.label
          }`;
      }

      setSearch(s);
      setPage('1');
      // setLimit(20);
    },
  });

  useEffect(() => {
    if (selectedSubject !== '') {
      setFieldValue('subject', selectedSubject?.value);
    }
  }, [selectedSubject]);

  const columns: TableColumn<any>[] = [
    {
      name: '#',
      width: '50px',
      cell: (row, index) => <strong>{((page - 1) * limit + index) + 1}</strong>,
    },
    // {
    //   name: <div>Photo</div>,
    //   selector: (row) => row?.personal_info.picture,
    //   cell: (row, index) => (
    //     <>
    //       {row?.personal_info?.picture ? (
    //         <img
    //           src={config.APP_URL + row?.personal_info?.picture}
    //           alt="Profile Photo"
    //           data-holder-rendered="true"
    //           className="img-thumbnail my-1 rounded-circle avatar-md"
    //         />
    //       ) : (
    //         ''
    //       )}
    //     </>
    //   ),
    //   width: "100px",
    // },

    {
      name: <div>Full Name</div>,
      selector: (row) => row?.personal_info?.full_name,
      wrap: true,
    },

    {
      name: <div>BMDC Reg. No.</div>,
      selector: (row) => row?.bmdc_registration_no,
    },
    {
      name: <div>Reg. Type</div>,
      selector: (row) => row?.registration_type,
    },
    {
      name: <div>Subject Name</div>,
      selector: (row) => row?.subject?.subject_name,
      wrap: true,
    },
    {
      name: <div>Program Type</div>,
      selector: (row) => row?.current_session_and_supervisor_info?.slot_info?.training_type === 'Advance' ? 
      'Speciality' 
      : row?.current_session_and_supervisor_info?.slot_info?.training_type ?? '--',
    },
    {
      name: <div>Slot</div>,
      selector: (row) => row?.current_session_and_supervisor_info?.slot_info?.name ?? '--',
      wrap: true,
    },

    {
      name: <div>Action</div>,
      cell: (row, index) => (
        <div className='my-1'>
          <button
            className="btn btn-sm btn-primary"
            onClick={() => {
              setReportId(row.user_id);
            }}
          >
            <span className="fas fa-eye"></span> View
          </button>
          {reportId === row.user_id ? (
            <SixMonthlyReportPDF userInfo={row} setReportId={setReportId} />
          ) : null}
        </div>
      ),
    },
  ];

  document.title = 'Six Monthly Report | BCPS';

  return (
    <div className="page-content">
      {isLoading || isFetching ? (
        <div className="overlay">
          <img src={loader} alt="Loading" height={100} width={300} />
        </div>
      ) : null}
      <Container fluid>
        <BetterBreadcrumb
          title="Six Monthly Report"
          breadcrumbItem={[
            { link: '#', name: 'Monitoring' },
          ]}
        />
        <Card>
          <CardBody>
            <Row>
              <Col>
                <div className="gap-2 ">
                  <Form
                    className="custom-form"
                    onSubmit={(e) => {
                      e.preventDefault();
                      searchSubmit();
                    }}
                  >
                    <div className="filter-wrapper mb-0">
                      <FormGroup>
                        <Label for="bmdc_registration_no">BMDC Reg. No.</Label>
                        <InputField
                          type="text"
                          placeholder="BMDC Reg. No."
                          className="form-control"
                          {...searchGetFieldProps('bmdc_registration_no')}
                        />
                      </FormGroup>

                      <FormGroup>
                        <Label for="subject">Subject</Label>
                        <Select
                          isClearable={true}
                          isSearchable={true}
                          value={selectedSubject}
                          onChange={setSelectedSubject}
                          options={allSubjects}
                          placeholder='Select...'
                          name="subject"
                        />
                      </FormGroup>

                      <FormGroup className="btn-column mb-3">
                        <Button
                          color="danger"
                          type="reset"
                          text="Reset"
                          onClick={() => {
                            searchResetForm();
                            searchSubmit();
                            setSelectedSubject('');
                          }}
                        />

                        <Button
                          type="button"
                          text="Search"
                          onClick={() => {
                            searchSubmit();
                          }}
                        />
                      </FormGroup>
                    </div>
                  </Form>
                </div>
              </Col>
            </Row>
            <DataTable
              columns={columns}
              data={data ? data : []}
              pagination
              paginationServer
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handlePerRowsChange}
              progressPending={isLoading}
              paginationTotalRows={reportList?.data?.total}
              expandOnRowClicked
              highlightOnHover
              paginationRowsPerPageOptions={[2, 5, 10, 20, 50, 100, 200]}
              persistTableHead={true}
            />
          </CardBody>
        </Card>
      </Container>
    </div>
  );
};

export default TraineeListForSixMonthReport;
